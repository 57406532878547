import Vue from 'vue';
import Router from 'vue-router';
import routerConfig from './config';

Vue.use(Router);

const router = new Router({ routes: routerConfig, mode: 'history', });

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
   // document.title = title;
  }
  next();
});

export default router;
