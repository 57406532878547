<template lang="pug">
  #app
    #loading
      .loading-inner
        i
    <!--#logs(v-if="istest")-->
      <!--.flex_center.gutter_span-->
        <!--van-button(@click="reload" size="small") 刷新-->
        <!--van-button(@click="clear" size="small") 清空-->
      <!--.logs-->
        <!--.log(v-for="(t, index) in logs" :key="index") {{ t }}-->
    router-view
</template>
<script>

import bluetooth from "@/bluetooth/pdd";
import utils from "@/common/utils";
import auth from '@/common/auth'

const openSdk = window.openSdk;

export default {
  data() {
    return {
      istest: true,
      logs: []
    }
  },
  mounted() {
    window.$log.addListener('default', (log) => {
      this.logs.push(log);
    })
    const {code} = utils.queryHref();
    if (code) {
      this.$http.auth(code).then((res) => {
        // 授权成功后才能调用其他 api
        this.$http.getPddCode('mobile').then(()=>{
          auth.init()
        })
        window.history.replaceState(null, null, window.location.pathname);
        const [sysToken, pddToken, apiVersion] = res.data;
        return openSdk.auth({accessToken: pddToken})
      }).then((res) => {
        // window.$log.addLog('sysToken: ' + JSON.stringify(res));
        // window.$log.addLog('ready')
        bluetooth.addListener();
      }).catch(e => {
        // window.$log.addLog('openSdk.auth: ' + JSON.stringify(e));
      })
    } else {
      openSdk.auth({accessToken: utils.get('pddToken')})
          .then(() => {
            // 授权成功后才能调用其他 api
            // window.$log.addLog('ready')
            bluetooth.addListener();
          })
          .catch((err) => {
            // window.$log.addLog('err: ' + JSON.stringify(err))
          })
    }
  },
  methods: {
    reload() {
      window.location.reload();
    },
    clear() {
      window.$log.clear();
      this.logs = [];
    }
  }
}
</script>
<style lang="stylus">
  #app
    padding-bottom 50px
  #logs
    padding .25rem
    background-color white
    .logs
      max-height 70vh
      overflow-y auto
  #loading
    background-color transparent
    position fixed
    top 0
    left 0
    right 0
    bottom 0
    z-index 9999
    display flex
    align-items center
    justify-content center
    visibility hidden
    .loading-inner
      width 100px
      height 100px
      transition transform .5s
      display flex
      align-items center
      justify-content center
      background-color rgba(0, 0, 0, .5)
      border-radius 12px
      i
        width: 40px;
        height: 40px;
        line-height: 40px;
        margin-bottom: 8px;
        animation: spin .4s linear infinite;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMjAiIGhlaWdodD0iMTIwIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgxMDB2MTAwSDB6Ii8+PHJlY3Qgd2lkdGg9IjciIGhlaWdodD0iMjAiIHg9IjQ2LjUiIHk9IjQwIiBmaWxsPSIjRTlFOUU5IiByeD0iNSIgcnk9IjUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgLTMwKSIvPjxyZWN0IHdpZHRoPSI3IiBoZWlnaHQ9IjIwIiB4PSI0Ni41IiB5PSI0MCIgZmlsbD0iIzk4OTY5NyIgcng9IjUiIHJ5PSI1IiB0cmFuc2Zvcm09InJvdGF0ZSgzMCAxMDUuOTggNjUpIi8+PHJlY3Qgd2lkdGg9IjciIGhlaWdodD0iMjAiIHg9IjQ2LjUiIHk9IjQwIiBmaWxsPSIjOUI5OTlBIiByeD0iNSIgcnk9IjUiIHRyYW5zZm9ybT0icm90YXRlKDYwIDc1Ljk4IDY1KSIvPjxyZWN0IHdpZHRoPSI3IiBoZWlnaHQ9IjIwIiB4PSI0Ni41IiB5PSI0MCIgZmlsbD0iI0EzQTFBMiIgcng9IjUiIHJ5PSI1IiB0cmFuc2Zvcm09InJvdGF0ZSg5MCA2NSA2NSkiLz48cmVjdCB3aWR0aD0iNyIgaGVpZ2h0PSIyMCIgeD0iNDYuNSIgeT0iNDAiIGZpbGw9IiNBQkE5QUEiIHJ4PSI1IiByeT0iNSIgdHJhbnNmb3JtPSJyb3RhdGUoMTIwIDU4LjY2IDY1KSIvPjxyZWN0IHdpZHRoPSI3IiBoZWlnaHQ9IjIwIiB4PSI0Ni41IiB5PSI0MCIgZmlsbD0iI0IyQjJCMiIgcng9IjUiIHJ5PSI1IiB0cmFuc2Zvcm09InJvdGF0ZSgxNTAgNTQuMDIgNjUpIi8+PHJlY3Qgd2lkdGg9IjciIGhlaWdodD0iMjAiIHg9IjQ2LjUiIHk9IjQwIiBmaWxsPSIjQkFCOEI5IiByeD0iNSIgcnk9IjUiIHRyYW5zZm9ybT0icm90YXRlKDE4MCA1MCA2NSkiLz48cmVjdCB3aWR0aD0iNyIgaGVpZ2h0PSIyMCIgeD0iNDYuNSIgeT0iNDAiIGZpbGw9IiNDMkMwQzEiIHJ4PSI1IiByeT0iNSIgdHJhbnNmb3JtPSJyb3RhdGUoLTE1MCA0NS45OCA2NSkiLz48cmVjdCB3aWR0aD0iNyIgaGVpZ2h0PSIyMCIgeD0iNDYuNSIgeT0iNDAiIGZpbGw9IiNDQkNCQ0IiIHJ4PSI1IiByeT0iNSIgdHJhbnNmb3JtPSJyb3RhdGUoLTEyMCA0MS4zNCA2NSkiLz48cmVjdCB3aWR0aD0iNyIgaGVpZ2h0PSIyMCIgeD0iNDYuNSIgeT0iNDAiIGZpbGw9IiNEMkQyRDIiIHJ4PSI1IiByeT0iNSIgdHJhbnNmb3JtPSJyb3RhdGUoLTkwIDM1IDY1KSIvPjxyZWN0IHdpZHRoPSI3IiBoZWlnaHQ9IjIwIiB4PSI0Ni41IiB5PSI0MCIgZmlsbD0iI0RBREFEQSIgcng9IjUiIHJ5PSI1IiB0cmFuc2Zvcm09InJvdGF0ZSgtNjAgMjQuMDIgNjUpIi8+PHJlY3Qgd2lkdGg9IjciIGhlaWdodD0iMjAiIHg9IjQ2LjUiIHk9IjQwIiBmaWxsPSIjRTJFMkUyIiByeD0iNSIgcnk9IjUiIHRyYW5zZm9ybT0icm90YXRlKC0zMCAtNS45OCA2NSkiLz48L3N2Zz4=');
        background-size: 100%;

</style>
