import resource from "@/api/resource";
import store from "@/store/index"
import waybill_config from "@/common/waybill_config";
import config from "@/common/config";
const pddId2LogisticsCompanies = {}, logisticsCompanyMap = {};
function buildData(groupArr) {
    groupArr.forEach(it => initSingleItem(it));
    let item;
    if (groupArr.length === 1) {
        item = groupArr[0];
    } else {
        const it2 = groupArr[0];
        it2.mergeOrders = groupArr.slice(1);
        item = it2;
    }
    item.totalGoodsCount = 0;
    item.totalPayAmount = 0;
    groupArr.forEach(it => {
        item.totalGoodsCount += it.goodsCount;
        item.totalPayAmount += it.payAmount;
    })
    return item;
}
const initSingleItem = (it) => {
    // 订单状态，售后状态，风控状态
    const {orderStatus, afterSalesStatus} = it;

    it.createDate = shorteningDate(it.confirmTime);
    it.payTime = shorteningDate(it.payTime);
    it.shippingTimeLabel = shorteningDate(it.shippingTime);
    // it.showPicture = false;
    it.checked = false;
    // it.editRemark = false;
    it.remark2 = it.remark;

    it.receiverNameShow = true;
    it.receiverPhoneShow = true;
    it.receiverAddressShow = true;

    it.locked = true === it.locked;

    it.orderStatusLabel = config.statusMap[orderStatus];
    it.afterSaleSymbol = config.afterStatusMap[afterSalesStatus] || '未知';
    it.canLock = it.orderStatus === 1 && it.refundStatus === 1  && it.printStatus === 0;
    it.canPrint = it.canLock || (it.waybillCode && it.wpCode);
    it.canSend = it.orderStatus === 1 && it.refundStatus === 1;
    // 快递单号以及物流处理
    generateCode(it);
    // 计算剩余时间
    calc(it);
}
const shorteningDate = (t) => {
    if (!t) {
        return '';
    }
    const year = new Date().getFullYear(), year2 = new Date(t).getFullYear();
    const format = year === year2 ? 'MM-dd HH:mm:ss' : 'yyyy-MM-dd HH:mm';
    return window.DateFormat.format.date(t, format);
}

const generateCode = (it) => {
    const {wpCode, waybillCode, logisticsId, trackingNumber, parentWaybillCode} = it;
    if (!wpCode && !logisticsId) {
        it.waybill = {};
        return;
    }
    // 发货单号 wpSendCode, 母单号 wpParentCode,  子单号 childWpCode 多单号 wpCodes, 多个单号或子单号的标识 wpTag
    let wpName = '', wpName2 = '', wpSendCode = '', wpParentCode = parentWaybillCode,
        childWpCode = [], wpCodes = [], waybillCodes = [],
        wpTag = '', errorWaybill = false;
    if (waybillCode) {
        waybillCodes = waybillCode.split(',');
    }
    // 1. 考虑本系统打印，拼多多后台发货
    if (logisticsId) {
        const wp = pddId2LogisticsCompanies[logisticsId];
        wpName = wp ? wp.name : '';
    }
    if (wpCode) {
        const wp = logisticsCompanyMap[wpCode];
        const tempWpName2 = wp ? wp.name : '';
        if (!wpName && tempWpName2) {
            wpName = tempWpName2;
        } else if (wpName && tempWpName2 && tempWpName2 !== wpName) {
            wpName2 = tempWpName2;
        }
    }
    // 2. 展示单号， 已发货，子母单，拆单
    if (trackingNumber) {
        wpSendCode = trackingNumber;
    } else if (wpParentCode) {
        wpSendCode = wpParentCode;
    } else if (waybillCodes.length > 0) {
        wpSendCode = waybillCodes[0];
    }

    // 是否是异常单, (已发货) && (发货单号不在本平台，或者使用本地台回收单号发货)
    if (trackingNumber) {
        // 有发货单号，才有可能是异常单
        if (parentWaybillCode) {
            // 是子母单，但是发货单号不是母单号
            if (trackingNumber !== parentWaybillCode) {
                errorWaybill = true;
            }
        } else if (waybillCode) {
            // 是普通单，但是发货单号，不在已打印的单号中
            if (trackingNumber !== waybillCode && !waybillCodes.includes(trackingNumber)) {
                errorWaybill = true;
            }
        }
    }

    if (wpParentCode) {
        // 1. 是子母单
        childWpCode = waybillCodes;
        wpTag = '子' + childWpCode.length;
    } else if (waybillCodes.length > 0) {
        if (waybillCodes.length > 1) {
            wpTag = '多' + (errorWaybill ? waybillCodes.length : waybillCodes.length - 1);
        }
        if (errorWaybill) {
            wpCodes = waybillCodes;
        } else {
            const index = waybillCodes.indexOf(wpSendCode);
            waybillCodes.splice(index, 1);
            // 3. 是多单
            if (waybillCodes.length > 0) {
                wpCodes = waybillCodes;
            }
        }
    }

    const logisticList = [], logisticMap = it.logisticMap;
    if (logisticMap) {
        Object.keys(logisticMap).forEach(it2 => {
            if (it2 === wpParentCode || it2 === wpSendCode || waybillCodes.includes(it2)) {
                logisticList.push({waybillCode: it2, logistic: logisticMap[it2]})
            }
        })
    }
    it.waybill = {
        wpTag,
        wpName,
        wpSendCode,
        wpParentCode,
        childWpCode,
        wpCodes,
        errorWaybill,
        logisticList,
        wpName2,
        trackingNumber,
        logisticMap
    }
}
const calc = (item) => {
    let leftSecond = (item.lastShipTime - new Date().getTime()) / 1000;
    if (leftSecond < 0) {
        item.leftTime = -1;
        item.period = 0;
        item.totalHour = 0;
        return;
    }
    item.totalHour = leftSecond / 3600;
    //1.先计算天
    const day = leftSecond / 86400;
    if (day > 1) {
        const hour = Math.floor(leftSecond % 86400 / 3600);
        item.leftTime = Math.floor(day) + '天' + (hour > 0 ? hour + '小时' : '');
    } else {
        //2.计算小时
        const hour = leftSecond / 3600;
        if (hour > 1) {
            item.leftTime = Math.floor(hour) + '小时';
        } else {
            const minutes = Math.floor(leftSecond / 60);
            item.leftTime = minutes > 0 ? minutes + '分钟' : leftSecond + '秒';
        }
    }
    const period = leftSecond * 1000 / (item.lastShipTime - item.confirmTime);
    item.period = period * 100 < 5 ? 5 : Math.floor(period * 100);
    return item;
};
export default {
    getAllPopOrders: (req, pageReq) => {
        // const {page, size, sort} = pageReq, sort2 = !sort ? '' : sort instanceof Array ? sort.map(it => 'sort=' + it).join('&') : 'sort=' + sort;
        return resource.request({
            // ?page=${page}&size=${size}&${sort2}
            url: `/pop/order/form/order_query`,
            method: 'post',
            data: JSON.stringify(req),
            params: pageReq
        }).then(res => {
            const {content} = res.data;
            if (!content || content.length === 0) {
                return res;
            }
            const shopsMap = store.getters.shopsMap || {}, blackList = store.getters.blackList || [], addressSpecial2List = [], promiseList = [], blackList1 = {};
            const {hasPromise, displayMerge, addressSpecial, splitPreSale} = req;
            content.forEach(it => {
                if (!it.mallName) {
                    it.mallName = shopsMap[it.ownerId] ? shopsMap[it.ownerId].name : '';
                }
                if (it.receiverPhoneIndex && blackList.indexOf(it.receiverPhoneIndex) > -1) {
                    it.isBlack = true;
                    blackList1[it.receiverPhoneIndex] = {
                        receiverPhoneEnc: it.receiverPhoneEnc,
                        receiverPhone: it.receiverPhone,
                        receiverPhoneIndex: it.receiverPhoneIndex,
                        receiverNameEnc: it.receiverNameEnc,
                        receiverName: it.receiverName,
                        ownerId: it.ownerId,
                        orderSn: it.orderSn
                    };
                }
                it.addressEnc = it.province + it.city + it.town + it.receiverAddressEnc;
                if (it.addressSpecial === 2) {
                    addressSpecial2List.push({
                        receiverPhoneEnc: it.receiverPhoneEnc,
                        receiverPhone: it.receiverPhone,
                        receiverPhoneIndex: it.receiverPhoneIndex,
                        receiverNameEnc: it.receiverNameEnc,
                        receiverName: it.receiverName,
                        ownerId: it.ownerId,
                        orderSn: it.orderSn
                    });
                }
                if (it.hasPromise) {
                    promiseList.push({
                        receiverPhone: it.receiverPhone,
                        receiverPhoneEnc: it.receiverPhoneEnc,
                        receiverPhoneIndex: it.receiverPhoneIndex,
                        receiverName: it.receiverName,
                        receiverNameEnc: it.receiverNameEnc,
                        ownerId: it.ownerId,
                        orderSn: it.orderSn
                    })
                }
                if (it.promises && it.promises.length > 0) {
                    it.promises.forEach(it2 => {
                        it2.updateTime = window.DateFormat.format.date(new Date(it2.updatedAt), 'yyyy-MM-dd HH:mm:ss');
                        const desc = JSON.parse(it2.detailInfo);
                        if (it2.promiseType === 1) {
                            it2.detail = waybill_config.wpCodeMap[desc.logistics_code].pddName;
                            it2.logistics_code = desc.logistics_code;
                        } else if (it2.promiseType === 2) {
                            it2.detail = desc.promise_delivery_time;
                            it2.promise_delivery_time = desc.promise_delivery_time;
                        }
                    })
                }
            });
            if (!hasPromise) {
                res.data.promiseList = promiseList;
            }
            res.data.blackStatistic = Object.values(blackList1);
            if (addressSpecial !== 2) {
                res.data.addressSpecial2List = addressSpecial2List;
            }
            if (!displayMerge) {
                for (let i = 0; i < content.length; i++) {
                    initSingleItem(content[i]);
                }
                return res;
            }
            const map = {}, arr = [];
            content.forEach(it => {
                it.isLike = 0;
                const uniqueKey = `${it.packageId}_${it.ownerId}${splitPreSale ? '_' + it.isPreSale : ''}`;
                if (!map[uniqueKey]) {
                    const groupArr = [it];
                    map[uniqueKey] = groupArr;
                    arr.push(groupArr);
                } else {
                    map[uniqueKey].push(it);
                }
            });
            const packageList = [];
            for (let i = 0; i < arr.length; i++) {
                packageList.push(buildData(arr[i]));
            }
            // 只有代发货未打印无售后有“相同电话多个地址”订单
            const likeStatistic = [], phone2Address2Packages = {};
            packageList.filter(it => it.orderStatus === 1 && it.printStatus === 0 && it.refundStatus === 1 && !it.locked)
                .forEach((val) => {
                        const phone = `${val.receiverPhoneIndex}_${val.receiverPhoneEnc}`,
                            rai = val.receiverAddressIndex;
                        if (!phone2Address2Packages[phone]) {
                            phone2Address2Packages[phone] = {[rai]: [val]};
                        } else if (!phone2Address2Packages[phone][rai]) {
                            phone2Address2Packages[phone][rai] = [val];
                        } else {
                            phone2Address2Packages[phone][rai].push(val);
                        }
                    }
                );
            Object.values(phone2Address2Packages).forEach(address2Packages => {
                const addresses = Object.keys(address2Packages);
                if (addresses.length === 1 && address2Packages[addresses[0]].length > 1) {
                    // 对于区分预售与非预售来说，还需要将里面的值，进行进一步的判断
                    if (splitPreSale) {
                        const isPreSale2Packages = {};
                        address2Packages[addresses[0]].forEach(it => {
                            if (!isPreSale2Packages[it.isPreSale]) {
                                isPreSale2Packages[it.isPreSale] = [];
                            }
                            isPreSale2Packages[it.isPreSale].push(it);
                        });
                        Object.values(isPreSale2Packages).filter(v => v.length > 1).forEach(packages => {
                            packages.forEach(it3 => {
                                it3.canMerge = 1;
                                if (it3.mergeOrders) {
                                    it3.mergeOrders.forEach(it4 => {
                                        it4.canMerge = 1;
                                    })
                                }
                            })
                        });
                    } else {
                        address2Packages[addresses[0]].forEach(it3 => {
                            it3.canMerge = 1;
                            if (it3.mergeOrders) {
                                it3.mergeOrders.forEach(it4 => {
                                    it4.canMerge = 1;
                                })
                            }
                        })
                    }
                } else if (Object.keys(address2Packages).length > 1) {
                    const packages = Object.values(address2Packages), it = packages[0][0];
                    packages.forEach(it2 => {
                        it2.forEach(it3 => {
                            it3.isLike = 1;
                            it3.canMerge = 1;
                            if (it3.mergeOrders) {
                                it3.mergeOrders.forEach(it4 => {
                                    it4.isLike = 1;
                                    it4.canMerge = 1;
                                })
                            }
                        })
                    })
                    likeStatistic.push({
                        receiverPhoneEnc: it.receiverPhoneEnc,
                        receiverPhoneIndex: it.receiverPhoneIndex,
                        receiverPhone: it.receiverPhone,
                        receiverNameEnc: it.receiverNameEnc,
                        receiverName: it.receiverName,
                        orderSn: it.orderSn,
                        ownerId: it.ownerId
                    });
                }
            })
            res.data.content = packageList;
            res.data.likeStatistic = likeStatistic;
            return res;
        })
    },
    queryOrderNum: (param) => resource.post('/pop/order/form/order_tab_query', JSON.stringify(param), {hideLoading: true}),
    min_times: (ownerId) => resource.get('/user/min_times', {params: {ownerId}}),
    countExceptionLogistic: params => resource.post("/order-logistic/form/exception-statistic-count", JSON.stringify(params), {hideLoading: true}),
}