import utils from '@/common/utils'

const state = {
  discoveredBluetoothDevices: [],
  currentBluetoothDevices: [],
}


const actions = {}

const mutations = {
  clearDiscoveredBluetoothDevices(state) {
    state.discoveredBluetoothDevices = [];
  },
  clearCurrentBluetoothDevices(state) {
    state.currentBluetoothDevices = [];
  },
  updateDiscoveredBluetoothDevices(state, list) {
    let bindPrinterList = utils.get('bindPrinterList');
    let arr = state.discoveredBluetoothDevices;
    list.forEach(it=>{
      if (bindPrinterList && bindPrinterList.length > 0) {
          let objList = bindPrinterList.filter(i=>i.uuid===it.uuid);
          if (objList && objList.length > 0) {
            return;
          }
      }
      let objList = arr.filter(i=>i.uuid===it.uuid);
      if (!objList || objList.length === 0) {
        arr.push(it);
      }
    })
    state.currentBluetoothDevices = list;
    state.discoveredBluetoothDevices = arr;
  },
}

export default {
  state,
  mutations,
  actions,
}
