<template lang="pug">
    router-view
</template>

<script>
export default {
  name: "index",
  data() {
    return {}
  },
}
</script>

<style scoped lang="stylus">
  #first_inner_view
    padding .5rem .25rem
</style>