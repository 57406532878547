import Api from '@/api'
import store from '@/store'
import Utils from '@/common/utils'

const getPddArea = function () {
  Api.getLogisticAddress().then(res=>{
    console.log(res.data)
    Utils.set('areaList',res.data);
  })
}

 const init = () => {
  return new Promise((resolve) => {
    store.dispatch('getUserInfo').then(res => {
      return Promise.all([
        getPddArea(),
        store.dispatch('getCommonTemplate'),
        store.dispatch('getShopList'),
        store.dispatch('getTemplateList'),
        store.dispatch('getDeliveryAddressList'),
      ])
    })
  })
}

export default {
  init
}
