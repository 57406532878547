import {Toast} from "vant";

const SYSTEM_CONFIG = "SYSTEM_CONFIG";
let cache;
const get = (key) => {
    if (cache) {
        return cache[key];
    }
    const val = localStorage.getItem(SYSTEM_CONFIG);
    let value = {};
    if (val) {
        value = JSON.parse(val);
    }
    cache = value;
    return value[key];
}
const set = (key, value) => {
    if (cache) {
        cache[key] = value;
    } else {
        const val = localStorage.getItem(SYSTEM_CONFIG);
        let obj = {};
        if (val) {
            obj = JSON.parse(val);
        }
        cache = obj;
    }
    localStorage.setItem(SYSTEM_CONFIG, JSON.stringify(cache));
}

const arr = [];
let timeout;
const hideLoading = function () {
    arr.pop();
    clearTimeout(timeout);
    timeout = setTimeout(() => {
        if (arr.length === 0) {
            document.querySelector('#loading').style.visibility = 'hidden'
        }
    }, 200)

}

const showLoading = function () {
    if (arr.length === 0) {
        document.querySelector('#loading').style.visibility = 'visible'
    }
    arr.push(1);
}

const queryHref = function () {
    const param = location.href.split('?');
    if (param.length < 2) return {};

    const params = param[1].split('#')[0].split('&')
    const res = {};
    params.forEach(it => {
        const [name, value] = it.split('=');
        res[name] = value;
    })
    return res
}

const formateDate = function (d, format = "yyyy-MM-dd HH:mm:ss") {
    if (!d) return '--'

    let date = null;
    if (d instanceof Date) {
        date = d;
    } else if (d instanceof Number) {
        date = new Date(d);
    }

    let fixBit = num => {
        if (num < 10) num = '0' + num
        return num
    }
    const formats = format.split(/[\-\s:]/g)
    let year = date.getFullYear()
    let month = fixBit(date.getMonth() + 1)
    let day = fixBit(date.getDate())
    let hour = fixBit(date.getHours())
    let minute = fixBit(date.getMinutes())
    let second = fixBit(date.getSeconds())

    return year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second
}
const copyText = text => {
    const input = document.createElement('textarea')
    document.body.appendChild(input)

    input.setAttribute('readonly', 'readonly')
    input.value = text;
    input.select()

    if (document.execCommand('copy')) {
        document.execCommand('copy')
        Toast(`已复制：${text}`)
    } else {
        Toast(`您的浏览器暂不支持一键复制哦`)
    }
    document.body.removeChild(input)
}
const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
function getUUID(len, radix){
    const uuid = [];
    radix = radix || chars.length;
    if (len) {
        for (let i = 0; i < len; i++) uuid[i] = chars[0 | Math.random()*radix];
    } else {
        uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
        uuid[14] = '4';
        for (let i = 0; i < 36; i++) {
            if (!uuid[i]) {
                let r = 0 | Math.random()*16;
                uuid[i] = chars[(i === 19) ? (r & 0x3) | 0x8 : r];
            }
        }
    }
    return uuid.join('');
}
export default {
    get,
    set,
    hideLoading,
    showLoading,
    queryHref,
    formateDate,
    copyText,
    getUUID
}
