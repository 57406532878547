import api from '@/api'
import supportTemplate from '@/bluetooth/supportTemplate'
import utils from '@/common/utils.js'

const state = {
  shippingAddressList: [],
  shopList: [],
  currentShop: {},
  info: {},
  templateList:[],
  commonStdTemplates:[],
  mergeOrderFlag: true,
  blackList: []
}

const actions = {
  getShopList({commit}) {
    api.getShopByUser().then(res => {
      commit('updateShopList', res.data)
    })
  },
  async getUserInfo({commit}) {
    const res = await api.getAuthMe()
    commit('updateUser', res.data)
    return res;
  },
  getDeliveryAddressList({commit}) {
    return api.getDeliveryAddressList().then(res => {
      commit('updateDeliveryAddressList', res.data);
      return res.data;
    })
  },
  async getCommonTemplate({commit}) {
    const res = await api.getCommonStdTemplates();
    commit('updateCommonStdTemplates', res.data.filter(it => it.type === 0));
  },
  getTemplateList({commit}) {
    return api.getUserTemplates().then(res => {
      // 筛选符合的模板
      let arr = res.data;
      if (!arr || arr.length === 0) {
        return [];
      }
      let supportTemplateIdList = supportTemplate.supportTemplateIdList;
      arr = arr.filter(it=>supportTemplateIdList.includes(it.parentTemplateId));
      if (!arr || arr.length === 0) {
        return [];
      }
      let relation = utils.get('templateAndPrinter');
      if (!relation) {
        relation = [];
      }
      arr.forEach(it=>{
        let objList = relation.filter(i=>i.templateId === it.id)
        if (objList && objList.length > 0) {
          it.printer = objList[0].printer;
        } else {
          it.printer = {};
        }
      });
      return api.getWaybillAccountBalance().then(res2 => {
        // 所有面单余额
        const balances = res2.data;
        arr.forEach(it => {
          // 0 自有 1分享
          if (1 === it.source) {
            const obj = balances.filter(i => i.waybillAccountId === it.waybillAccountId)[0];
            const balance = Number(obj.quantity), usedCount = Number(obj.allocatedQuantity);
            it.quantity = balance === -1 ? '无限' : balance - usedCount;
            it.allocatedQuantity = usedCount;
            it.cancelQuantity = '—';
            it.recycledQuantity = '—'
          } else {
            let list = balances.filter(i => it.wpCode === i.wpCode && it.ownerId === i.ownerId && it.address === i.address);
            it.quantity = list.length > 0 ? list[0].quantity : '获取失败';
            it.allocatedQuantity = list.length > 0 ? list[0].allocatedQuantity : '获取失败';
            it.cancelQuantity = list.length > 0 ? list[0].cancelQuantity : '获取失败';
            it.recycledQuantity = list.length > 0 ? list[0].recycledQuantity : '获取失败';
          }
        })
        return res.data;
      })
    }).then(res => {
      commit('updateTemplateList', res);
      return res;
    })
  },
  updateWaybillBalanceAction({commit}) {
    return api.getWaybillAccountBalance().then(res => {
      commit('updateWaybillBalance', res.data);
    })
  },

}

const mutations = {
  changeMergeOrderFlag(state, v) {
    state.mergeOrderFlag = v;
  },
  updateShopList(state, list) {
    list.forEach(it => initShop(it));
    state.shopList = list;
    if (state.info.currentShop) {
      const shopId = state.info.currentShop, newShop = list.find(it => it.ownerId === shopId);
      state.currentShop = {...state.currentShop, ...newShop};
    }
  },
  updateUser(state, info) {
    state.info = info
    if (info.currentShop && state.shopList.length > 0) {
      const shopId = info.currentShop, newShop = state.shopList.find(it => it.ownerId === shopId);
      state.currentShop = {...state.currentShop, ...newShop};
    }
  },
  updateCommonStdTemplates(state, info) {
    state.commonStdTemplates = info
  },
  updateDeliveryAddressList(state, list) {
    state.shippingAddressList = list;
  },
  updateTemplateList(state, list) {
    state.templateList = list;
  },
  updateWaybillBalance(state, balances) {
    const arr = [...state.templateList];
    arr.forEach(it => {
      // 0 自有 1分享
      if (1 === it.source) {
        const obj = balances.filter(i => i.waybillAccountId === it.waybillAccountId)[0];
        const balance = Number(obj.quantity), usedCount = Number(obj.allocatedQuantity);
        it.quantity = balance === -1 ? '无限' : balance - usedCount;
        it.allocatedQuantity = usedCount;
      } else {
        let list = balances.filter(i => it.wpCode === i.wpCode && it.ownerId === i.ownerId && it.address === i.address);
        it.quantity = list.length > 0 ? list[0].quantity : '获取失败';
        it.allocatedQuantity = list.length > 0 ? list[0].allocatedQuantity : '获取失败';
        it.cancelQuantity = list.length > 0 ? list[0].cancelQuantity : '获取失败';
        it.recycledQuantity = list.length > 0 ? list[0].recycledQuantity : '获取失败';
      }
    })
    state.templateList = arr;
  },
  updateTemplatePrinter(state,obj) {
    const arr = [...state.templateList];
    let objList = arr.filter(it=>it.id === obj.templateId)
    if (objList && objList.length > 0) {
      let obj1 = objList[0]
      obj1.printer = obj.printer;
    }
    state.templateList = arr;
  }
}

function initShop(shop) {
  if (!shop || !shop.expireTime) {
    return;
  }
  shop.expireTime = utils.formateDate(new Date(shop.expireTime))
}

export default {
  state,
  mutations,
  actions,
}
