import resouce from "./resource";

export default {
  // 校验订单状态
  validOrder: list => resouce.post("/print/valid_orders", JSON.stringify(list), {hideLoading: true}),

  getWaybillCode: params => resouce.post("/print/get_waybill_code_orders", JSON.stringify(params), {hideLoading: true}),

  getUserTemplates: () => resouce.get("/get_user_templates", {hideLoading: true}),

  getWaybillAccountBalance: () => resouce.get("/get_pop_waybill_account_balance", {hideLoading: true}),

  cmdPrint: params => resouce.post("/print/cloud_print", JSON.stringify(params), {hideLoading: true}),

  notifyPrintSuccess: params => resouce.post("/notify_print_result", JSON.stringify(params), {hideLoading: true}),
}
