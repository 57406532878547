<template lang="pug">
  .view_wrapper
    .flex_space_between.flex_center.p8
      .left
        div.flex_center
          span.shop_name.p4.mr8 {{currentShop.name}}
          span.version {{currentShop.version}}
        div.info.p4
          | 有效期至 {{currentShop.expireTime}}
      van-button(@click="showChangeShop=true" round size="small" type="primary") 切换店铺
    router-view.global_view
    van-tabbar(v-model="active" @change="changeTab")
      van-tabbar-item(v-for="t in tabs" :icon="t.icon" :name="t.path" :key="t.path") {{t.name}}
    van-popup(v-model="showChangeShop" position="bottom" style="width: 100%;height: 35%" get-container="body" )
      van-picker(title="切换店铺" show-toolbar
          :visible-item-count="4" :columns="shopList.filter(it=>it.shopType !== 1)" value-key="name" :columns-field-names="{text: 'name'}"
          @cancel="showChangeShop = false" @confirm="changeShop")
</template>

<script>
import {mapGetters,mapMutations} from "vuex";
import {Toast} from 'vant';

export default {
  name: "tabs",
  data() {
    return {
      shopNameList:[],
      showChangeShop:false,
      active: '',
      tabs: [
        {name: '首页', path: '/home', icon: 'wap-home-o'},
        {name: '订单', path: '/order', icon: 'orders-o'},
        {name: '我的', path: '/me', icon: 'user-o'},
      ]
    }
  },
  computed: {
    ...mapGetters(['currentShop','shopList'])
  },
  watch: {
    $route(v) {
      this.active = v.path;
    }
  },
  created() {
    this.active = this.$route.path;
  },
  methods: {
    ...mapMutations(['updateUser']),
    changeTab(path) {
      this.$router.push({path});
    },
    changeShop(item) {
      if (item.ownerId === this.currentShop.ownerId) {
        Toast.fail('已是当前店铺，无需切换！')
        this.showChangeShop = false;
        return;
      }
      if (new Date(item.expireTime).getTime() < new Date().getTime()) {
        Toast.fail('店铺已过期，请先续费！')
        this.showChangeShop = false;
        return;
      }
      const ownerId = item.ownerId;
      this.$http.changeCurrentShop({ownerId}).then(res => {
        this.updateUser(res.data);
        Toast.success('切换成功');
        location.reload();
      })
    }
  }
}
</script>

<style scoped lang="stylus">
.global_view
  padding 0 .5rem .5rem!important
.view_wrapper
  .version
    background-image linear-gradient(90deg, #e66465, #9198e5)
    font-size 12px
    border-radius 4px
    padding 4px .5rem
    color white
</style>
