<template lang="pug">
  div(ref="cont" :style="divStyle")
    slot(name="default")
</template>

<script>
export default {
  name: "DynamicText",
  data() {
    return {
      loaded: false
    }
  },
  computed: {
    divStyle() {
      if (!this.loaded) {
        return {};
      }
      const style = {};
      const cont = this.$refs.cont;
      if (cont) {
        const clientWidth = cont.offsetWidth;
        const {width} = this.measureText(this.$slots.default[0].text, 12);
        const fs = clientWidth * 12 / width;
        style.fontSize = (fs > 24 ? 24 : fs < 12 ? 12 : fs) + 'px';
      }
      return style;
    }
  },
  mounted() {
    this.loaded = true;
  },
  methods: {
    measureText(pText, pFontSize) {
      let lDiv = document.createElement('div');
      document.body.appendChild(lDiv);

      lDiv.style.fontSize = "" + pFontSize + "px";
      lDiv.style.position = "absolute";
      lDiv.style.left = -1000;
      lDiv.style.top = -1000;

      lDiv.innerHTML = pText;

      const lResult = {
        width: lDiv.clientWidth,
        height: lDiv.clientHeight
      };

      document.body.removeChild(lDiv);
      lDiv = null;

      return lResult;
    }
  }
}
</script>

<style scoped lang="stylus">

</style>