export const getters = {

  userInfo: state => state.user.info,
  shopList: state => state.user.shopList,
  currentShop: state => state.user.currentShop,
  mergeOrderFlag: state => state.user.mergeOrderFlag,
  blackList: state => state.user.blackList,
  shippingAddressList: state => state.user.shippingAddressList,
  templateList: state => state.user.templateList,
  commonStdTemplates: state => state.user.commonStdTemplates,



  discoveredBluetoothDevices: state => state.print.discoveredBluetoothDevices,
  currentBluetoothDevices: state => state.print.currentBluetoothDevices,


}
