import resouce from './resource';

export default {
  auth: (code) => {
    return resouce.get(`/auth?code=${code}`);
  },
  getAuthMe: params => resouce.get('users/me', {params}),

  getShopByUser: () => resouce.get("get_shop_by_user", {hideLoading: true}),

  getDeliveryAddressList: () => resouce.get('/shipping-address', {hideLoading: true}),

  getCommonStdTemplates: () => resouce.get("/get_common_templates", {hideLoading: true}),
}
