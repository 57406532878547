import Vue from 'vue';
import App from './App';
import router from './router';
import api from '@/api/index'
import './stylus/iconfont.css';
import './common/dateUtil';
import store from './store'
import DynamicText from './components/DynamicText'
import CopyOp from './components/CopyOp'
import {
  Button, Tabbar, TabbarItem, NavBar, Tab, Tabs, Popup, Collapse, CollapseItem, Cell, CellGroup,SwipeCell, Calendar,Checkbox, CheckboxGroup,
  DropdownMenu, DropdownItem, Tag, Icon, Picker, Switch, AddressList, AddressEdit, Area, Circle,Form,Field,  Empty,RadioGroup,Radio,
  Dialog, Toast, List, PullRefresh
} from 'vant';

Vue.use(Tabbar).use(TabbarItem).use(SwipeCell)
  .use(Tab).use(Tabs).use(Form).use(Field)
  .use(Collapse).use(CollapseItem).use(Circle)
  .use(DropdownMenu).use(DropdownItem)
  .use(Picker).use(Cell).use(CellGroup)
  .use(Popup).use(Switch).use(Area).use(Checkbox)
  .use(Icon).use(AddressList).use(AddressEdit).use(CheckboxGroup)
  .use(Tag).use(Empty).use(Calendar).use(Dialog).use(Toast)
  .use(NavBar).use(Radio).use(RadioGroup).use(List).use(PullRefresh)
  .use(Button);
Vue.use(DynamicText).use(CopyOp);
Vue.prototype.$http = api;
Vue.prototype.$dialog = Dialog;
window.$log = {
  logs: [],
  listener: {default: []},
  addLog(log) {
    const log2 = typeof log === 'string' ? log : JSON.stringify(log);
    this.logs.push(log2);
    this.listener.default.forEach(func => {
      func(log2);
    })
    if (this.logs.length > 100) {
      this.logs = [];
    }
  },
  addListener(name = 'default', listener) {
    this.listener[name].push(listener);
  },
  clear() {
    this.logs = [];
  }
};
new Vue({
  router,
  el: '#app',
  store,
  render: h => h(App)
});
