/**
 * Api 文档
 */
import Axios from 'axios'
import qs from 'qs'
import Utils from "../common/utils";
import utils from "../common/utils";

const currentMode = process.env.NODE_ENV;
const VUE_APP_BASE_URL = process.env.VUE_APP_BASE_URL;
const isPdd = navigator.userAgent.includes('pdd');
const url = (isPdd ? VUE_APP_BASE_URL : location.origin) + "/api";

let requestUrl = '';
const contentTypeName = 'Content-Type', applicationJson = "application/json";
const resource = Axios.create({
    baseURL: url,
    timeout: 50000, // 请求超时时间
    headers: {
        post: {
            [contentTypeName]: applicationJson
        },
        put: {
            [contentTypeName]: applicationJson
        }
    }
})
const bapp_uni_key = utils.getUUID(16);
let reqIndex = 0;
resource.defaults.transformRequest = (data, headers) => {
    headers['api-version'] = Utils.get('apiVersion');
    if (currentMode === 'development') {
        headers['X-Cloud-Staging'] = "staging";
    }
    if (!headers['X-Web-Trace-Id']) {
        headers['X-Web-Trace-Id'] = 'bapp_' + bapp_uni_key + '_' + (reqIndex++);
    }
    const contentType = headers[contentTypeName] || headers.post[contentTypeName];
    if (typeof data === 'string' && contentType !== applicationJson) {
        // 对于表单提交修改
        return qs.stringify(JSON.parse(data))
    } else {
        return data
    }
}
// Add a request interceptor
resource.interceptors.request.use(config => {
    requestUrl = config.url;
    if (!config.params) config.params = {}
    if (!config.hideLoading && !config.params.__hideLoading) Utils.showLoading()
    if (config.params.__hideLoading) delete config.params.__hideLoading
    const token = Utils.get('Token')
    if (token) config.headers.common['Authorization'] = 'Bearer ' + token

    const pageCode = Utils.get('PageCode'), pati = Utils.get('Pati');
    if (pageCode) config.headers.common['X-PDD-PageCode'] = pageCode;
    if (pati) config.headers.common['X-PDD-Pati'] = pati;

    if (requestUrl.startsWith('pdd/control/decrypt/v1')) {
        const authToken = Utils.get('verifyAuthToken');
        if (authToken) {
            config.headers['X-PDD-VerifyAuthToken'] = authToken;
        }
        config.baseURL = config.baseURL.replace('/api', '');
    }

    return config
}, error => {
    return Promise.reject(error)
})

// Add a response interceptor
resource.interceptors.response.use(response => {
    if (!response.config.hideLoading && !response.config.params.__hideLoading) {
        Utils.hideLoading()
    }
    if (response.config.url.indexOf('pdd/code/get') > -1) {
        let pageCode = response.data;
        if (pageCode) {
            Utils.set('PageCode', pageCode);
            window.PDD_OPEN_init({
                code: pageCode
            }, function () {
                window.PDD_OPEN_getPati().then(pati => {
                    if (pati) {
                        Utils.set('Pati', pati);
                    }
                })
            })
        }
    }
    if (response.config.url.indexOf('/auth?code=') > -1) {
        let [sysToken, pddToken, apiVersion] = response.data;
        let bearer = sysToken.split(" ");
        let token = bearer && bearer[1] ? bearer[1] : null;
        if (token) Utils.set('Token', token);
        Utils.set('pddToken', pddToken);
        Utils.set('apiVersion', apiVersion);
    }

    return response
}, error => {
    try {
        const status = error.response.status
        if (status === 401) {
            if (requestUrl !== 'authenticate') {
                Utils.toAuth();
            }
            return Promise.reject(error);
        }
        if (requestUrl === 'authenticate' && status === 423) {
            return Promise.reject(error)
        }
    } catch (e) {
        console.error(e);
    } finally {
        Utils.hideLoading()
    }
    return Promise.reject(error)
})

export default resource
