import order from './order';
import user from './user';
import print from './print'
import Ajax from './resource'


export default {
  ...order,
  ...user,
  ...print,

  getLogisticAddress: () => Ajax.get("/get_logistic_address"),

  sendOutGoodsAllShop: params => Ajax.post("/pop/order/notify-online-by-shop", JSON.stringify(params)),

  changeCurrentShop: params => Ajax.get('/shop/change/' + params.ownerId),

  getPddCode: (param) => Ajax.get(param ? `pdd/code/get?referer=${param}` : 'pdd/code/get', {hideLoading: true}),
};
