<template lang="pug">
  i.iconfont.icon-copy01(@click="handleClick")
</template>

<script>
import utils from "@/common/utils";

export default {
  name: "CopyOp",
  props: ['text'],
  methods: {
    handleClick() {
      utils.copyText(this.text)
    }
  }
}
</script>

<style scoped lang="stylus">

</style>