import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import print from './modules/print'
import {getters} from "./getters";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    user,
    print
  },
  getters,
  strict: debug,
  plugins: [createPersistedState({
    storage: window.localStorage,
    reducer(val) {
      return {
        user: val.user,
        print: val.print
      }
    }
  })]
})
