/**
 * 路由配置，影响 面包屑 路由初始化
 *
 * 一级 component 为 layout 默认为 baseLayout 带顶部菜单和左边菜单
 *
 * 每一级的 name 和 meta.title 必填
 * {
    path: '/merchant',
    name: 'merchant',
    component: baseLayout,
    meta: { title: '商户管理', icon: 'ios-people' },
 * }
 * meta字段完整项
 *  meta: {
      title: 导航和面包屑显示标题
      hideInMenu: (default: false) 设为true后在左侧菜单不会显示该页面选项
      horizontalHide: 水平方向的目录隐藏，
      verticalHide: 垂直方向的目录隐藏
      icon: (default: -) 该页面在左侧菜单、面包屑和标签导航处显示的图标，如果是自定义图标，需要在图标名称前加下划线'_'
        href: 'https://xxx' (default: null) 用于跳转到外部连接
 *  }
 *
 * 配置 layout
 *  baseLayout 带顶部状态栏 和 左侧导航
 *
 */
import tabsLayout from '@/view/layout/tabs'
import baseLayout from '@/view/layout/index'

const routerConfig = [
  {
    path: '*',
    redirect: '/home'
  },
  {
    path: '/',
    component: tabsLayout,
    redirect: '/home',
    children: [
      {
        name: 'me',
        path: 'me',
        component: () => import('../view/me/index'),
        meta: {
          title: '会员中心'
        }
      },
      {
        name: 'order',
        path: 'order',
        component: () => import('../view/order/index'),
        meta: {
          title: '购物车'
        }
      },
      {
        name: 'home',
        path: '/home',
        component: () => import('../view/home/index'),
        meta: {
          title: '商品详情'
        }
      },
    ]
  },
  {
    name: 'address/list',
    path: '/address/list',
    component: () => import('../view/me/shippingAddress'),
  },
  {
    name: 'address/create',
    path: '/address/create',
    component: () => import('../view/me/createAddress'),
  },
  {
    name: 'shop/manage',
    path: '/shop/manage',
    component: () => import('../view/me/shopManage'),
  },
  {
    name: 'printer',
    path: '/printer',
    component: () => import('../view/print/printer'),
  },
  {
    name: 'print/template/list',
    path: '/print/template/list',
    component: () => import('../view/print/printTemplateList'),
  },
  {
    name: 'print_log',
    path: '/print_log',
    component: () => import('../view/print_log/index'),
  },

];
// add route path
// routerConfig.forEach(route => {
//     route.path = route.path || '/' + (route.name || '');
// });
export default routerConfig
