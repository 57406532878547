const supportTemplateList = [
  {id: 51, name: '圆通一联单', wpCode: 'YTO'},
  {id: 46, name: '中通一联单', wpCode: 'ZTO'},
  {id: 49, name: '韵达一联单', wpCode: 'YUNDA'},
  {id: 50, name: '申通一联单', wpCode: 'STO'},
  {id: 37, name: '百世一联单', wpCode: 'HT'},
  {id: 64, name: '天天一联单', wpCode: 'TT'},
  {id: 53, name: '邮政小包一联单', wpCode: 'YZXB'},
  {id: 56, name: '德邦一联单', wpCode: 'DB'},
  {id: 87, name: '极兔一联单', wpCode: 'JTSD'}];

const supportTemplateIdList = [51, 46, 49, 50, 37, 64, 53, 56, 87];

const logisticsCompanyMap = {
  "YTO": {
    "id": 85,
    "name": "圆通快递",
    "wpCode": "YTO",
    "logo": "https://pinduoduoimg.yangkeduo.com/msfe/2019-01-16/a5015c87a6a4b51ad01df2f73502e317.png"
  },
  "STO": {
    "id": 1,
    "name": "申通快递",
    "wpCode": "STO",
    "logo": "https://pinduoduoimg.yangkeduo.com/msfe/2019-01-17/172779e0c4587a929d9bc9495c9cdd66.png"
  },
  "YUNDA": {
    "id": 121,
    "name": "韵达快递",
    "wpCode": "YUNDA",
    "logo": "https://pinduoduoimg.yangkeduo.com/msfe/2019-01-17/546c001d958ffedef1ad7fc53ea3d4f2.png"
  },
  "ZTO": {
    "id": 115,
    "name": "中通快递",
    "wpCode": "ZTO",
    "logo": "https://pinduoduoimg.yangkeduo.com/msfe/2019-01-16/c3d5eebec471a5c5536e3cad7dc02a21.png"
  },
  "HT": {
    "id": 3,
    "name": "百世快递",
    "wpCode": "HT",
    "logo": "https://testimg.yangkeduo.com/express/2018-07-03/7ef6ea0e08037a6dd287111bb496a710.png"
  },
  "TT": {
    "id": 119,
    "name": "天天快递",
    "wpCode": "TT",
    "logo": "https://testimg.yangkeduo.com/express/2018-07-03/e5366dca534d278c1568e587c613b054.png"
  },
  "AIR": {
    "id": 317,
    "name": "亚风速递",
    "wpCode": "AIR",
    "logo": "https://t16img.yangkeduo.com/mms_static/2019-03-18/f07a8899-cbca-48a3-8112-bb5c232f521b.jpeg"
  },
  "YS": {
    "id": 117,
    "name": "优速快递",
    "wpCode": "YS",
    "logo": "https://pinduoduoimg.yangkeduo.com/print_template/2019-04-15/b0c167b31b9036a63bf6e466e5d3ca8f.png"
  },
  "SF": {
    "id": 44,
    "name": "顺丰快递",
    "wpCode": "SF",
    "logo": "https://testimg.yangkeduo.com/express/2018-07-19/c0215420d8466459115fa5fd30035df5.png"
  },
  "RRS": {
    "id": 227,
    "name": "日日顺物流",
    "wpCode": "RRS",
    "logo": "https://pinduoduoimg.yangkeduo.com/print_template/2019-06-05/187265fcc9dce854c713ea7ced048205.png"
  },
  "YDKY": {
    "id": 344,
    "name": "韵达快运",
    "wpCode": "YDKY",
    "logo": "https://pinduoduoimg.yangkeduo.com/print_template/2019-06-26/e8b9f6f1f26b3c820693428e6dbc6f0e.png"
  },
  "YZXB": {
    "id": 132,
    "name": "邮政快递包裹",
    "wpCode": "YZXB",
    "logo": "https://pinduoduoimg.yangkeduo.com/msfe/2019-02-24/c6eb4dbf541ac75e8c7497319dd09a1c.png"
  },
  "KYE": {
    "id": 183,
    "name": "跨越速运",
    "wpCode": "KYE",
    "logo": "https://pinduoduoimg.yangkeduo.com/msfe/2019-03-11/a1c127c684310a99db41c7dc1b419640.png"
  },
  "SDSD": {
    "id": 354,
    "name": "D速物流",
    "wpCode": "SDSD",
    "logo": "https://t16img.yangkeduo.com/mms_static/2019-03-20/d215bf9c-acab-428f-8aa1-5c5e1531af79.jpeg"
  },
  "ZTOKY": {
    "id": 343,
    "name": "中通快运",
    "wpCode": "ZTOKY",
    "logo": "https://t16img.yangkeduo.com/mms_static/2019-03-26/21ec9b18-7e53-4909-9197-f13e8710c21a.jpg"
  },
  "DB": {
    "id": 131,
    "name": "德邦快递",
    "wpCode": "DB",
    "logo": "https://t16img.yangkeduo.com/mms_static/2019-03-20/f229de71-2ea4-44fd-bff2-deff56bdb2b2.jpeg"
  },
  "ZJS": {
    "id": 129,
    "name": "宅急送快递",
    "wpCode": "ZJS",
    "logo": "https://t16img.yangkeduo.com/mms_static/2019-04-04/359c0517-7958-43d8-b922-4ddec7d910d4.jpg"
  },
  "OTP": {
    "id": 351,
    "name": "承诺达特快",
    "wpCode": "OTP",
    "logo": "https://pinduoduoimg.yangkeduo.com/print_template/2019-04-12/2426ae7e9774eff18b5f0a0dc75a3b28.png"
  },
  "AXWL": {
    "id": 352,
    "name": "安迅物流",
    "wpCode": "AXWL",
    "logo": "https://pinduoduoimg.yangkeduo.com/print_template/2019-04-17/d49709f1b15a4adc827470d413e45dd0.png"
  },
  "SZKKE": {
    "id": 281,
    "name": "京广速递",
    "wpCode": "SZKKE",
    "logo": "https://pinduoduoimg.yangkeduo.com/print_template/2019-05-07/dbb58a82dbef8faecacd8400f6e7d6d6.png"
  },
  "SXJD": {
    "id": 358,
    "name": "顺心捷达",
    "wpCode": "SXJD",
    "logo": "https://pinduoduoimg.yangkeduo.com/print_template/2019-05-23/b48fc63421d8efcea7c1dbc49f49dec1.png"
  },
  "EMS": {
    "id": 118,
    "name": "邮政EMS",
    "wpCode": "EMS",
    "logo": "https://t16img.yangkeduo.com/mms_static/2019-09-06/518a616b-a34a-413b-89d4-cce6e7334183.png"
  },
  "JD": {
    "id": 120,
    "name": "京东配送",
    "wpCode": "JD",
    "logo": "https://t16img.yangkeduo.com/mms_static/2019-10-08/e7c2b6df-10f4-495d-b2ab-6781b6347343.png"
  },
  "SFKY": {
    "id": 372,
    "name": "顺丰快运",
    "wpCode": "SFKY",
    "logo": "https://t16img.yangkeduo.com/mms_static/2019-10-16/ce6f0035-ca91-451c-9965-9deff0578e12.png"
  },
  "DEBANGWULIU": {
    "id": 230,
    "name": "德邦物流",
    "wpCode": "DEBANGWULIU",
    "logo": "https://t16img.yangkeduo.com/mms_static/2019-10-11/sdsd-2b0f-4289-b42c-3cba51a590f4.png"
  },
  "BESTQJT": {
    "id": 229,
    "name": "百世快运",
    "wpCode": "BESTQJT",
    "logo": "https://t16img.yangkeduo.com/mms_static/2019-08-19/d8953929-5916-4fb1-b17c-c98245ad2c23.png"
  },
  "HOAU": {
    "id": 210,
    "name": "天地华宇",
    "wpCode": "HOAU",
    "logo": "https://t16img.yangkeduo.com/mms_static/2019-11-22/fd2e1b82-62d0-4f3d-a50c-889f69a8264a.png"
  },
  "JIUYE": {
    "id": 136,
    "name": "九曳供应链",
    "wpCode": "JIUYE",
    "logo": "https://t16img.yangkeduo.com/mms_static/2019-12-29/3684c82a-c7d9-4960-b247-dc696d1e1755.jpg"
  },
  "JTSD": {
    "id": 384,
    "name": "极兔速递",
    "wpCode": "JTSD",
    "logo": "https://t16img.yangkeduo.com/mms_static/2020-02-03/dc6822ad-9415-4164-9f11-d5a48c341aac.png"
  },
  "YMDD": {
    "id": 348,
    "name": "壹米滴答",
    "wpCode": "YMDD",
    "logo": "https://funimg.pddpic.com/2021-01-08/701799dd-d768-4a18-a1f4-eeb3c0252246.png.slim.png"
  },
  "FENGWANG": {
    "id": 403,
    "name": "丰网速运",
    "wpCode": "FENGWANG",
    "logo": "https://funimg.pddpic.com/2021-01-13/dade87f1-f36d-4afc-b932-019892283317.png"
  }
}

const bluetoothTypeList = [
  {print: 'KM-360', type: 'CPCL'},
  {print: 'QR-386A', type: 'CPCL'},
  {print: 'F01', type: 'CPCL'},
  {print: 'CS3', type: 'CPCL'},
  {print: 'K316', type: 'CPCL'},
  {print: 'KT319', type: 'CPCL'},
  {print: 'SP201', type: 'CPCL'},
  {print: 'T1', type: 'CPCL'},
  {print: 'UC888', type: 'CPCL'},
  {print: 'XT423', type: 'CPCL'},
  {print: 'UT888', type: 'CPCL'},
  {print: 'ZKES', type: 'CPCL'},
  {print: 'ZTO588', type: 'CPCL'},
  {print: 'ZTO688', type: 'CPCL'},
  {print: 'HM-A300', type: 'CPCL'},
  {print: 'QR-488BT', type: 'TSPL'},
  {print: 'JLP352', type: 'CPCL'},
  {print: 'N41BT', type: 'TSPL'},
  {print: 'KM-202BT', type: 'TSPL'},
  {print: 'JMD452', type: 'TSPL'},
  {print: 'QR380A', type: 'CPCL'},
  {print: 'FHD01', type: 'CPCL'},
  {print: 'XP460B', type: 'CPCL'},
];

const tsplTypeList = ['QR-488BT', 'N41BT', 'KM-202BT', 'JMD452']

export default {
  supportTemplateList,
  supportTemplateIdList,
  tsplTypeList,
  logisticsCompanyMap
}
