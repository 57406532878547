/**
 * wpCode 默认使用pdd的code，name，
 * type 0 表示公共的， 1 表示 pdd 独有的， 3 表示淘外独有的， 4 表示菜鸟独有的
 * logo 快递公司图标
 * showService 是否展示 增值服务
 */
const wpOptions = [
  {
    "wpCode": "ZTO",
    "pddName": "中通快递",
    "type": 0,
    "logo": "https://pinduoduoimg.yangkeduo.com/msfe/2019-01-16/c3d5eebec471a5c5536e3cad7dc02a21.png"
  }, {
    "wpCode": "YUNDA",
    "pddName": "韵达快递",
    "type": 0,
    "logo": "https://pinduoduoimg.yangkeduo.com/msfe/2019-01-17/546c001d958ffedef1ad7fc53ea3d4f2.png"
  }, {
    "wpCode": "YTO",
    "pddName": "圆通快递",
    "type": 0,
    "tbName": "圆通速递",
    "logo": "https://pinduoduoimg.yangkeduo.com/msfe/2019-01-16/a5015c87a6a4b51ad01df2f73502e317.png"
  }, {
    "wpCode": "HT",
    "pddName": "百世快递",
    "type": 0,
    "tbCode": "HTKY",
    "logo": "https://testimg.yangkeduo.com/express/2018-07-03/7ef6ea0e08037a6dd287111bb496a710.png"
  }, {
    "wpCode": "YZXB",
    "pddName": "邮政快递包裹",
    "type": 0,
    "tbCode": "POSTB",
    "logo": "https://pinduoduoimg.yangkeduo.com/msfe/2019-02-24/c6eb4dbf541ac75e8c7497319dd09a1c.png"
  }, {
    "wpCode": "STO",
    "pddName": "申通快递",
    "type": 0,
    "logo": "https://pinduoduoimg.yangkeduo.com/msfe/2019-01-17/172779e0c4587a929d9bc9495c9cdd66.png"
  }, {
    "wpCode": "TT",
    "pddName": "天天快递",
    "type": 0,
    "tbCode": "TTKDEX",
    "logo": "https://testimg.yangkeduo.com/express/2018-07-03/e5366dca534d278c1568e587c613b054.png"
  },
  {
    "wpCode": "JTSD",
    "pddName": "极兔速递",
    "type": 1,
    logo: "https://t16img.yangkeduo.com/mms_static/2020-02-03/dc6822ad-9415-4164-9f11-d5a48c341aac.png"
  },
  {
    "wpCode": "SF",
    "pddName": "顺丰快递",
    "type": 0,
    "tbName": "顺丰速运",
    "logo": "https://testimg.yangkeduo.com/express/2018-07-19/c0215420d8466459115fa5fd30035df5.png"
  }, {
    "wpCode": "DB",
    "pddName": "德邦快递",
    "type": 0,
    "tbCode": "DBKD",
    "logo": "https://t16img.yangkeduo.com/mms_static/2019-03-20/f229de71-2ea4-44fd-bff2-deff56bdb2b2.jpeg"
  }, {
    "wpCode": "AIR",
    "pddName": "亚风速递",
    "type": 1,
    "logo": "https://t16img.yangkeduo.com/mms_static/2019-03-18/f07a8899-cbca-48a3-8112-bb5c232f521b.jpeg"
  }, {
    "wpCode": "YS",
    "pddName": "优速快递",
    "type": 0,
    "tbCode": "UC",
    "logo": "https://pinduoduoimg.yangkeduo.com/print_template/2019-04-15/b0c167b31b9036a63bf6e466e5d3ca8f.png"
  }, {
    "wpCode": "EMS",
    "pddName": "邮政EMS",
    "type": 0,
    "tbName": "EMS",
    "logo": "https://t16img.yangkeduo.com/mms_static/2019-09-06/518a616b-a34a-413b-89d4-cce6e7334183.png"
  }, {
    "wpCode": "SFKY",
    "pddName": "顺丰快运",
    "type": 1,
    "logo": "https://t16img.yangkeduo.com/mms_static/2019-10-16/ce6f0035-ca91-451c-9965-9deff0578e12.png"
  }, {
    "wpCode": "BESTQJT",
    "pddName": "百世快运",
    "type": 0,
    "logo": "https://t16img.yangkeduo.com/mms_static/2019-08-19/d8953929-5916-4fb1-b17c-c98245ad2c23.png"
  }, {
    "wpCode": "YDKY",
    "pddName": "韵达快运",
    "type": 1,
    "logo": "https://pinduoduoimg.yangkeduo.com/print_template/2019-06-26/e8b9f6f1f26b3c820693428e6dbc6f0e.png",
    "showService": 1,
  }, {
    "wpCode": "ZTOKY",
    "pddName": "中通快运",
    "type": 0,
    "tbCode": "DISTRIBUTOR_13222803",
    "logo": "https://t16img.yangkeduo.com/mms_static/2019-03-26/21ec9b18-7e53-4909-9197-f13e8710c21a.jpg",
    "showService": 1,
  }, {
    "wpCode": "JD",
    "pddName": "京东配送",
    "type": 1,
    "logo": "https://t16img.yangkeduo.com/mms_static/2019-10-08/e7c2b6df-10f4-495d-b2ab-6781b6347343.png"
  }, {
    "wpCode": "ZJS",
    "pddName": "宅急送快递",
    "type": 0,
    "tbName": "宅急送",
    "logo": "https://t16img.yangkeduo.com/mms_static/2019-04-04/359c0517-7958-43d8-b922-4ddec7d910d4.jpg"
  }, {
    "wpCode": "SXJD",
    "pddName": "顺心捷达",
    "type": 0,
    "tbCode": "DISTRIBUTOR_13484485",
    "logo": "https://pinduoduoimg.yangkeduo.com/print_template/2019-05-23/b48fc63421d8efcea7c1dbc49f49dec1.png",
    "showService": 1
  }, {
    "wpCode": "DEBANGWULIU",
    "pddName": "德邦物流",
    "type": 0,
    "tbCode": "DBL",
    "logo": "https://t16img.yangkeduo.com/mms_static/2020-03-16/538bb3ef-166d-4929-9a2b-e4f5c414b230.png"
  }, {
    "wpCode": "GTO",
    "pddName": "国通快递",
    "tbName": "国通快递",
    "type": 0,
    "logo": "http://img.alicdn.com/tfs/TB1LhsEewMPMeJjy1XcXXXpppXa-126-30.png"
  }, {
    "wpCode": "SDSD",
    "pddName": "D速物流",
    "type": 1,
    "logo": "https://t16img.yangkeduo.com/mms_static/2019-03-20/d215bf9c-acab-428f-8aa1-5c5e1531af79.jpeg"
  }, {
    "wpCode": "SZKKE",
    "pddName": "京广速递",
    "type": 1,
    "logo": "https://pinduoduoimg.yangkeduo.com/print_template/2019-05-07/dbb58a82dbef8faecacd8400f6e7d6d6.png"
  }, {
    "wpCode": "OTP",
    "pddName": "承诺达特快",
    "type": 1,
    "logo": "https://pinduoduoimg.yangkeduo.com/print_template/2019-04-12/2426ae7e9774eff18b5f0a0dc75a3b28.png"
  }, {
    "wpCode": "AXWL",
    "pddName": "安迅物流",
    "type": 1,
    "logo": "https://pinduoduoimg.yangkeduo.com/print_template/2019-04-17/d49709f1b15a4adc827470d413e45dd0.png"
  }, {
    "wpCode": "RRS",
    "pddName": "日日顺物流",
    "type": 1,
    "logo": "https://pinduoduoimg.yangkeduo.com/print_template/2019-06-05/187265fcc9dce854c713ea7ced048205.png"
  }, {
    "wpCode": "KYE",
    "pddName": "跨越速运",
    "type": 1,
    "logo": "https://pinduoduoimg.yangkeduo.com/msfe/2019-03-11/a1c127c684310a99db41c7dc1b419640.png"
  }, {
    "wpCode": "HOAU",
    "pddName": "天地华宇",
    "tbName": "天地华宇",
    "type": 0,
    "logo": "https://t16img.yangkeduo.com/mms_static/2019-11-22/fd2e1b82-62d0-4f3d-a50c-889f69a8264a.png",
    "showService": 1
  }, {
    "wpCode": "JIUYE",
    "pddName": "九曳供应链",
    "type": 1,
    "logo": "https://t16img.yangkeduo.com/mms_static/2019-12-29/3684c82a-c7d9-4960-b247-dc696d1e1755.jpg"
  }, {
    "wpCode": "YMDD",
    "pddName": "壹米滴答",
    "type": 1,
    "logo": "https://funimg.pddpic.com/2021-01-08/701799dd-d768-4a18-a1f4-eeb3c0252246.png.slim.png"
  }, {
    "wpCode": "FENGWANG",
    "pddName": "丰网速运",
    "type": 1,
    "logo": "https://funimg.pddpic.com/2021-01-13/dade87f1-f36d-4afc-b932-019892283317.png"
  }];
const pddServiceMap = {
  "SFKY": [
    {
      "required": false,
      "attrs": [
        {
          "name": "value",
          "label": "顺丰快运时效服务",
          "type": "enum",
          "range": [
            {
              "value": "SE0100",
              "label": "重货包裹"
            },
            {
              "value": "SE0101",
              "label": "小票零担"
            },
            {
              "value": "SE0006",
              "label": "物流普运"
            },
            {
              "value": "SE0091",
              "label": "专线普运"
            },
            {
              "value": "SE0020",
              "label": "重货专运"
            },
            {
              "value": "SE0114",
              "label": "大票零担"
            }
          ]
        }
      ],
      "code": "TIMED-DELIVERY",
      "name": "时效服务"
    },
    {
      "required": true,
      "attrs": [
        {
          "name": "value",
          "label": "保价服务",
          "type": "number",
          "range": {
            "min": 0,
            "max": 999999999
          }
        }
      ],
      "code": "INSURE",
      "name": "保价服务"
    }
  ],
  "BESTQJT": [
    {
      "required": false,
      "attrs": [
        {
          "name": "value",
          "label": "百世配送增值服务",
          "type": "enum",
          "range": [
            {
              "value": "UPSTAIRS",
              "label": "上楼"
            },
            {
              "value": "WAREHOUSE",
              "label": "进仓"
            }
          ]
        }
      ],
      "code": "SVC-BESTQJT-DELIVERY-SERVICE",
      "name": "增值服务"
    },
    {
      "required": false,
      "attrs": [
        {
          "name": "value",
          "label": "到付金额",
          "type": "number",
          "range": {
            "min": 0,
            "max": 999999999
          }
        }
      ],
      "code": "SVC-BESTQJT-RECEIVER-PAY",
      "name": "百世到付"
    },
    {
      "required": true,
      "attrs": [
        {
          "name": "value",
          "label": "百世运输类型服务",
          "type": "enum",
          "range": [
            {
              "value": "ECOMMERCE",
              "label": "电商"
            }
          ]
        }
      ],
      "code": "SVC-BESTQJT-TRANSPORT-TYPE",
      "name": "运输类型"
    },
    {
      "required": true,
      "attrs": [
        {
          "name": "value",
          "label": "保价金额",
          "type": "number",
          "range": {
            "min": 0, "max": 999999999
          }
        }
      ],
      "code": "SVC-BESTQJT-INSURE",
      "name": "保价服务"
    },
    {
      "required": true,
      "attrs": [
        {
          "name": "value",
          "label": "百世配送类型服务",
          "type": "enum",
          "range": [
            {
              "value": "SELF",
              "label": "自提"
            },
            {
              "value": "SEND",
              "label": "送货"
            }
          ]
        }
      ],
      "code": "SVC-BESTQJT-DELIVERY-TYPE",
      "name": "配送类型"
    },
    {
      "required": false,
      "attrs": [
        {
          "name": "value",
          "label": "回单方式",
          "type": "enum",
          "range": [
            {
              "value": "RECEIPT",
              "label": "原单"
            },
            {
              "value": "PHOTO",
              "label": "拍照"
            }
          ]
        }
      ],
      "code": "SVC-BESTQJT-DELIVERY-RECEIPT",
      "name": "签收回单"
    }
  ],
  "SF": [
    {
      "required": false,
      "attrs": [
        {
          "name": "value",
          "label": "顺丰快递时效服务",
          "type": "enum",
          "range": [
            {
              "value": "1",
              "label": "顺丰标快"
            },
            {
              "value": "2",
              "label": "顺丰标快（陆运）"
            },
            {
              "value": "3",
              "label": "电商特惠"
            },
            {
              "value": "5",
              "label": "顺丰次晨"
            },
            {
              "value": "6",
              "label": "顺丰即日"
            },
            {
              "value": "11",
              "label": "医药安心递"
            },
            {
              "value": "12",
              "label": "医药专递"
            },
            {
              "value": "13",
              "label": "物流普运"
            },
            {
              "value": "15",
              "label": "生鲜速配"
            },
            {
              "value": "16",
              "label": "大闸蟹专递"
            },
            {
              "value": "18",
              "label": "重货快运"
            },
            {
              "value": "30",
              "label": "三号便利箱/袋"
            },
            {
              "value": "31",
              "label": "便利封"
            },
            {
              "value": "32",
              "label": "二号便利箱/袋"
            },
            {
              "value": "33",
              "label": "岛内件（80CM）"
            },
            {
              "value": "34",
              "label": "即日2200"
            },
            {
              "value": "36",
              "label": "汇票专送"
            },
            {
              "value": "110",
              "label": "证照专递"
            },
            {
              "value": "111",
              "label": "顺丰干配"
            },
            {
              "value": "112",
              "label": "顺丰空配"
            },
            {
              "value": "125",
              "label": "专线普运"
            },
            {
              "value": "153",
              "label": "重货专运"
            },
            {
              "value": "154",
              "label": "重货包裹"
            },
            {
              "value": "155",
              "label": "小票零担"
            },
            {
              "value": "195",
              "label": "医药安心递（陆）"
            },
            {
              "value": "199",
              "label": "极速包裹"
            },
            {
              "value": "200",
              "label": "冷运速配"
            },
            {
              "value": "201",
              "label": "冷运特惠"
            },
            {
              "value": "202",
              "label": "顺丰微小件"
            },
            {
              "value": "203",
              "label": "医药快运"
            },
            {
              "value": "204",
              "label": "陆运微小件"
            },
            {
              "value": "208",
              "label": "特惠专配"
            }
          ]
        }
      ],
      "code": "TIMED-DELIVERY",
      "name": "时效服务"
    },
    {
      "required": false,
      "hidden": true,
      "attrs": [
        {
          "name": "value",
          "label": "保鲜服务",
          "type": "enum",
          "range": [
            {
              "value": "",
              "label": "保鲜服务"
            }
          ]
        }
      ],
      "code": "IN41",
      "name": "保鲜服务"
    },
    {
      "required": false,
      "attrs": [
        {
          "name": "value",
          "label": "顺丰保价金额",
          "type": "number",
          "range": {
            "min": 0, "max": 999999999
          }
        }
      ],
      "code": "INSURE",
      "name": "保价服务"
    }
  ],
  "DEBANGWULIU": [
    {
      "required": true,
      "attrs": [
        {
          "name": "value",
          "label": "德邦时效产品",
          "type": "enum",
          "range": [
            {
              "value": "WXJTH",
              "label": "微小件特惠"
            },
            {
              "value": "JJDJ",
              "label": "经济大件"
            },
            {
              "value": "PACKAGE",
              "label": "标准快递"
            },
            {
              "value": "RCP",
              "label": "360特惠件"
            },
            {
              "value": "EPEP",
              "label": "电商尊享"
            },
            {
              "value": "DEAP",
              "label": "特准快件"
            },
            {
              "value": "ZBRH",
              "label": "3.60特重件"
            },
            {
              "value": "NZBRH",
              "label": "重包入户"
            },
            {
              "value": "ZBTH",
              "label": "重包特惠"
            },
            {
              "value": "CITYPIECE",
              "label": "同城件"
            }
          ]
        }
      ],
      "code": "SVC_TRANSPORTTYPE",
      "name": "时效产品"
    },
    {
      "required": false,
      "attrs": [
        {
          "name": "value",
          "label": "德邦物流保价服务",
          "type": "number",
          "range": {
            "min": 0, "max": 999999999
          }
        }
      ],
      "code": "SVC_INSURE",
      "name": "保价服务"
    }
  ],
  "SXJD": [
    {
      "required": false,
      "attrs": [
        {
          "name": "value",
          "label": "签单方式",
          "type": "enum",
          "range": [
            {
              "value": "ORIG_RECEIPT",
              "label": "原件回单"
            },
            {
              "value": "ElEC_RECEIPT",
              "label": "电子回单"
            }
          ]
        }
      ],
      "code": "SVC-SIGN-TYPE",
      "name": "签单方式"
    },
    {
      "required": true,
      "attrs": [
        {
          "name": "value",
          "label": "接货方式",
          "type": "enum",
          "range": [
            {
              "value": "SEND",
              "label": "上门接货"
            },
            {
              "value": "SELF",
              "label": "客户自送"
            }
          ]
        }
      ],
      "code": "SVC-RECEIVE-TYPE",
      "name": "接货方式"
    },
    {
      "required": true,
      "attrs": [
        {
          "name": "value",
          "label": "送货方式",
          "type": "enum",
          "range": [
            {
              "value": "SELF",
              "label": "自提"
            },
            {
              "value": "SEND_NO_ELEVATOR",
              "label": "送货上楼（无电梯）"
            },
            {
              "value": "SEND_HAS_ELEVATOR",
              "label": "送货上楼（有电梯）"
            },
            {
              "value": "SEND_NO_UPSTAIRS",
              "label": "送货（不含上楼）"
            }
          ]
        }
      ],
      "code": "SVC-DELIVERY-TYPE",
      "name": "送货方式"
    },
    {
      "required": true,
      "attrs": [
        {
          "name": "value",
          "label": "付款方式",
          "type": "enum",
          "range": [
            {
              "value": "ON-DELIVERY",
              "label": "现付"
            }
          ]
        }
      ],
      "code": "SVC-PAYMENT-TYPE",
      "name": "付款方式"
    },
    {
      "required": true,
      "attrs": [
        {
          "name": "value",
          "label": "保价金额",
          "type": "number",
          "range": {
            "min": 0, "max": 999999999
          }
        }
      ],
      "code": "SVC-INSURE",
      "name": "保价金额"
    }
  ],
  "HOAU": [
    {
      "required": true,
      "attrs": [
        {
          "name": "value",
          "label": "运输方式",
          "type": "enum",
          "range": [
            {
              "value": "10000000000000000001",
              "label": "定日达"
            },
            {
              "value": "30000000000000000001",
              "label": "经济快运"
            },
            {
              "value": "70000000000000000001",
              "label": "易安装"
            }
          ]
        }
      ],
      "code": "SVC-TRANSPORT-TYPE",
      "name": "运输方式"
    },
    {
      "required": true,
      "attrs": [
        {
          "name": "value",
          "label": "付款方式",
          "type": "enum",
          "range": [
            {
              "value": "CASH_PAYMENT",
              "label": "发货付清"
            },
            {
              "value": "SHIPPER_SETTLEMENT",
              "label": "发货结算"
            }
          ]
        }
      ],
      "code": "PAYMENT-TYPE",
      "name": "付款方式"
    },
    {
      "required": true,
      "attrs": [
        {
          "name": "value",
          "label": "交货方式",
          "type": "enum",
          "range": [
            {
              "value": "PICKUPSELF",
              "label": "自提"
            },
            {
              "value": "DELIVERY",
              "label": "送货上门"
            },
            {
              "value": "BIGUPSTAIRS",
              "label": "大件送货上楼"
            },
            {
              "value": "SMALLUPSTAIRS",
              "label": "小件送货上楼"
            }
          ]
        }
      ],
      "code": "SVC-DELIVERY-TYPE",
      "name": "交货方式"
    },
    {
      "required": true,
      "attrs": [
        {
          "name": "value",
          "label": "签收单",
          "type": "enum",
          "range": [
            {
              "value": "NO_RETURN_SIGNED",
              "label": "无需返单"
            },
            {
              "value": "CUSTOMER_SIGNED_ORIGINAL",
              "label": "签收单原件返回"
            },
            {
              "value": "CUSTOMER_SIGNED_FAX",
              "label": "客户签收单传真返回"
            }
          ]
        }
      ],
      "code": "returnbillType",
      "name": "签收单"
    },
    {
      "required": true,
      "attrs": [
        {
          "name": "value",
          "label": "保价",
          "type": "number",
          "range": {
            "min": 2000, "max": 999999999
          }
        }
      ],
      "code": "SVC-INSURE",
      "name": "保价"
    }
  ],
  "ZTOKY": [
    {
      "required": false,
      "attrs": [
        {
          "name": "value",
          "label": "运输类型",
          "type": "enum",
          "range": [
            {
              "value": "202",
              "label": "标准快运"
            }
          ]
        }
      ],
      "code": "SVC-TRANSPORT-TYPE",
      "name": "运输类型"
    },
    {
      "required": false,
      "attrs": [
        {
          "name": "value",
          "label": "派送方式",
          "type": "enum",
          "range": [
            {
              "value": "101",
              "label": "派送"
            },
            {
              "value": "102",
              "label": "自提"
            }
          ]
        }
      ],
      "code": "SVC-DELIVERY-TYPE",
      "name": "派送方式"
    },
    {
      "required": false,
      "attrs": [
        {
          "name": "value",
          "label": "付款方式",
          "type": "enum",
          "range": [
            {
              "value": "401",
              "label": "现金"
            }
          ]
        }
      ],
      "code": "SVC-PAYMENT-TYPE",
      "name": "付款方式"
    },
    {
      "required": false,
      "attrs": [
        {
          "name": "value",
          "label": "签收回单",
          "type": "enum",
          "range": [
            {
              "value": "301",
              "label": "电子/电子"
            },
            {
              "value": "302",
              "label": "电子/纸质"
            },
            {
              "value": "303",
              "label": "纸质/纸质"
            },
            {
              "value": "304",
              "label": "纸质/电子"
            }
          ]
        }
      ],
      "code": "SVC-RETURNBILL-TYPE",
      "name": "签收回单"
    }
  ],
  "YDKY": [
    {
      "required": true,
      "attrs": [
        {
          "name": "value",
          "label": "派送方式",
          "type": "enum",
          "range": [
            {
              "value": "100",
              "label": "派送"
            },
            {
              "value": "101",
              "label": "自提"
            },
            {
              "value": "102",
              "label": "送货上楼"
            }
          ]
        }
      ],
      "code": "DELIVERY-TYPE",
      "name": "派送方式"
    },
    {
      "required": true,
      "attrs": [
        {
          "name": "value",
          "label": "产品类型",
          "type": "enum",
          "range": [
            {
              "value": "1",
              "label": "标准快运"
            },
            {
              "value": "2",
              "label": "韵准达"
            },
            {
              "value": "3",
              "label": "粤准达"
            },
            {
              "value": "4",
              "label": "168大件"
            }
          ]
        }
      ],
      "code": "PRODUCT-TYPE",
      "name": "产品类型"
    },
    {
      "required": true,
      "attrs": [
        {
          "name": "value",
          "label": "保价金额",
          "type": "number",
          "range": {
            "min": 1000,
            "max": 100000
          }
        }
      ],
      "code": "INSURE",
      "name": "保价"
    },
    {
      "required": false,
      "attrs": [
        {
          "name": "value",
          "label": "回单",
          "type": "enum",
          "range": [
            {
              "value": "401",
              "label": "签回单"
            }
          ]
        }
      ],
      "code": "RECEIPT-TYPE",
      "name": "回单"
    },
    {
      "required": true,
      "attrs": [
        {
          "name": "value",
          "label": "业务类型",
          "type": "enum",
          "range": [
            {
              "value": "201",
              "label": "电商"
            }
          ]
        }
      ],
      "code": "BUSINESS-TYPE",
      "name": "业务类型"
    },
    {
      "required": false,
      "attrs": [
        {
          "name": "value",
          "label": "货物类型",
          "type": "enum",
          "range": [
            {
              "value": "301",
              "label": "易碎品"
            }
          ]
        }
      ],
      "code": "CARGO-TYPE",
      "name": "货物类型"
    }
  ]
};
const taobaoServiceMap = {};
const wpCodeMap = wpOptions.reduce((accu, it) => {
  accu[it.wpCode] = it;
  return accu;
}, {});
// 淘外面单类型
const taobaoWaybillType = {
    "1": "快递标准面单",
    "2": "快递三联面单",
    "3": "快递便携式三联单",
    "4": "快运标准面单",
    "5": "快运三联面单",
    "6": "快递一联单",
  },
// 拼多多标准模板类型
  pddWaybillType = {
    "1": "标准模板",
    "3": "一联单模板",
    "4": "快递便携式三联单",
    "5": "快运标准面单",
    "8": "快递定制一联单",
  };
const platformMap = {"3": "淘宝站外电子面单", "1": "拼多多站外电子面单", "0": "拼多多电子面单", "4": "菜鸟电子面单"};
export default {
  wpOptions, wpCodeMap, pddServiceMap, taobaoServiceMap, taobaoWaybillType, pddWaybillType,
  platformMap
}
