import {Dialog, Toast} from 'vant'
import supportTemplate from './supportTemplate'

const sdk = window.openSdk;

const eventCenter = {
  default: [() => {
  }]
};

const bluetoothListener = (t, func) => {
  if (!eventCenter[t]) {
    eventCenter[t] = [];
  }
  eventCenter[t].push(func);
}

const searchBluetoothDevices = async () => {
  const result = await sdk.bluetooth.getDiscoveredBluetoothDevices()
  let arr = [];
  if (result.devices && result.devices.length > 0) {
    arr = result.devices.filter(it => it.name)
  }
  if (arr.length > 0) {
    eventCenter['getDevices'].forEach(func => {
      func(arr);
    })
  }
}

const connectBluetoothDevice = (printer) => {
  Toast.loading({
    message: '正在连接打印机，请稍后！',
    duration: 0
  });
  sdk.bluetooth.connectBluetoothDevice({uuid: printer.uuid}).then(res => {
    let searchCharacteristicsNumber = 0;
    if (!res) {
      setTimeout(() => {
        getBLEDeviceCharacteristics(printer, searchCharacteristicsNumber);
      }, 500)
    } else {
      getBLEDeviceCharacteristics(printer, searchCharacteristicsNumber);
    }
  }).catch(() => {
    throw new Error('连接蓝牙打印机失败');
  })
}


//获取当前连接的蓝牙设备的 characteristic （特征值）
const getBLEDeviceCharacteristics = function (printer, searchCharacteristicsNumber) {
  searchCharacteristicsNumber++;
  let characteristicsUuId = '';
  sdk.bluetooth.getBluetoothCharacteristics().then(result => {
    let isE = false;
    if (result && result.characteristics) {
      let list = result.characteristics, types = printerTypeName(printer.name);
      characteristicsUuId = filterCharacter(list, types);
      if (characteristicsUuId) {
        isE = true;
      }
      if (isE) {
        searchCharacteristicsNumber = 0;
        eventCenter['getCharacteristicsUuId'].forEach(func => {
          func(characteristicsUuId);
        })
        return;
      }
    }
    if (!isE) {
      if (searchCharacteristicsNumber < 11) {
        setTimeout(() => {
          getBLEDeviceCharacteristics(printer, searchCharacteristicsNumber);
        }, 500);
      } else {
        Toast.clear()
        disconnectBluetoothDevice().then(() => {
          Dialog.confirm({
            title: '连接提示',
            message: '连接设备失败，请重试！',
            confirmButtonText: '确认并重试',
            cancelButtonText: '取消'
          }).then(() => connectBluetoothDevice(printer))
            .catch(() => {
            })
        })
      }
    }
  })
}

const disconnectBluetoothDevice = () => {
  return sdk.bluetooth.disconnectBluetoothDevice()
}


const printInfo = (characteristicsUuid, list) => {
  let glbCmd = "";
  list.forEach(it => {
    glbCmd += it;
  })
  return sdk.bluetooth.writeBluetoothCharacteristic({
    characteristicsUuid: characteristicsUuid,
    value: glbCmd
  })
}

const startDiscoveryBluetooth = function () {
  sdk.bluetooth.startDiscoveryBluetooth();
}

const stopDiscoveryBluetooth = function () {
  sdk.bluetooth.stopDiscoveryBluetooth();
}

const getCommandType = function (printerName) {
  let type = printerTypeName(printerName), tsplTypeList = supportTemplate.tsplTypeList;
  let tsplList = tsplTypeList.filter(it => it.startsWith(type));
  if (!tsplTypeList || tsplTypeList.length === 0) {
    return 'CPCL';
  }
  tsplTypeList = tsplTypeList.filter(it => it.indexOf(printerName) > -1);
  if (!tsplTypeList || tsplTypeList.length === 0) {
    return 'CPCL';
  }
  return 'TSPL';
}

const addListener = () => {
  const queryState = async () => {
    const state = await sdk.bluetooth.getBluetoothState()
    const checked = !!state.available
    if (checked) {
      // window.$log.addLog('蓝牙已开启')
    } else {
      // window.$log.addLog('蓝牙已关闭')
    }
  }

  const listenBluetoothDevicesFound = () => {
    sdk.on('bluetoothDevicesFound', () => {
      searchBluetoothDevices()
    })
  }

  queryState()
  listenBluetoothDevicesFound()

  sdk.on('bluetoothStateChange', async () => {
    await queryState()
  })
}

const printerTypeName = function (printerName) {
  let typeName = "";
  if (!printerName || printerName == undefined) {
    return typeName;
  }
  if (printerName.toUpperCase().startsWith("QR")) {
    typeName = "QR";
  } else if (printerName.toUpperCase().startsWith("HM")
    || (!printerName.toUpperCase().startsWith("DL") && printerName.toUpperCase().startsWith("D"))
    || printerName.toUpperCase().startsWith("N") || printerName.toUpperCase().startsWith("EK")) {
    typeName = "HM";
  } else if (printerName.toUpperCase().startsWith("GP")) {
    //GP暂时和HM（汉印）一个方法
    typeName = "HM";
  } else if (printerName.toUpperCase().startsWith("KM")) {
    typeName = "KM";
  } else if (printerName.toUpperCase().startsWith("XP")) {
    typeName = "XP";
  } else if (printerName.toUpperCase().startsWith("DL")) {
    typeName = "DL";
  }
  return typeName;
}

const filterCharacter = function (characteristics, printerName) {
  let types = printerTypeName(printerName);
  let writeCharacteristicId = "";
  if (types === "QR") {
    for (let i = 0; i < characteristics.length; i++) {
      if (characteristics[i].uuid.indexOf('49535343-8841-43F4-A8D4-ECBE34729BB3') != -1
        || characteristics[i].uuid.indexOf('49535343-8841-43f4-a8d4-ecbe34729bb3') != -1
        || characteristics[i].uuid.indexOf('49535343-aca3-481c-91ec-d85e28a60318') != -1
        || characteristics[i].uuid.indexOf('49535343-ACA3-481C-91EC-D85E28A60318') != -1) {
        writeCharacteristicId = characteristics[i].uuid;
        break;
      }
    }
  } else if (types === "HM") {
    for (let i = 0; i < characteristics.length; i++) {
      let charc = characteristics[i];
      if (charc.writeable) {
        writeCharacteristicId = charc.uuid;
        break;
      }
    }
  } else if (types === "KM") {
    var linshiUuid = "";
    for (let i = 0; i < characteristics.length; i++) {
      let charc = characteristics[i];
      let uuids = charc.uuid.toUpperCase();
      if (uuids.indexOf("0000EEE1-0000-1000-8000-00805F9B34FB") !== -1)//当前是快麦demo中的
      {
        writeCharacteristicId = charc.uuid;
        break;
      }
      if (charc.writeable) {//备用，假如上面匹配不上就可以使用上
        linshiUuid = charc.uuid;
      }
    }
    if (writeCharacteristicId === '') {
      writeCharacteristicId = linshiUuid;
    }
  } else if (types === "XP") {
    for (let i = 0; i < characteristics.length; i++) {
      let charc = characteristics[i];
      if (charc.writeable) {
        writeCharacteristicId = charc.uuid;
        break;
      }
    }
  } else if (types === "DL") {
    for (let i = 0; i < characteristics.length; i++) {
      let charc = characteristics[i];
      if (charc.writeable) {
        writeCharacteristicId = charc.uuid;
        break;
      }
    }
  }
  return writeCharacteristicId;
}

export default {
  addListener,
  startDiscoveryBluetooth,
  stopDiscoveryBluetooth,
  bluetoothListener,
  connectBluetoothDevice,
  getCommandType,
  printInfo
};
